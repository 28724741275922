import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import {Carousel} from 'react-responsive-carousel';
import Button from '@mui/material/Button';
import 'react-responsive-carousel/lib/styles/carousel.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';



const Landing = ({auth}) => {
  const [expanded, setExpanded] = useState('panel');
  const [loading, setLoading] = useState(true)
  const history = useHistory();

  useEffect(()=>{
    // console.log(auth)
    if (auth?.paid === true) {
      history.push("/family");
    } else if (auth?.paid === false) {
      setLoading(false)
    } else if (auth === false) {
      setLoading(false)
    }
  },[auth])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  
  return(
    loading ? <div style={{ justifyContent: 'center',display: 'flex'}}> <CircularProgress style={{marginTop: '50px'}} /> </div> :
    <div style={{marginTop: '40px', width: '100%'}}>
      <div>
        {/* <Carousel dynamicHeight autoPlay showArrows={true} showThumbs={false} infiniteLoop={true} interval={5000} style={{width: '100%'}}>
            <div>
                <img style={{height: '100%', objectFit: 'contain'}} src='https://images.ctfassets.net/7rldri896b2a/4BPRFvvgspEZJ1VSH38xkH/b79caa38646dddf33a907d91ba82f71c/KI-HERO-DreamKitchenDSKsat.jpg' alt="fine rug example" />
                <div style={{position: 'absolute', top: '20%',transform: 'translateY(-50%)', left: '20%',transform: 'translateX(-50%)', background: 'rgba(0, 0, 0, .2)'}}>
                    <h1 style={{color: 'white', fontSize: '50px'}}>Title 1</h1>
                    <p>MyDisplay.io</p>
                </div>
            </div>
            <div>
                <img src='https://images.ctfassets.net/7rldri896b2a/4BPRFvvgspEZJ1VSH38xkH/b79caa38646dddf33a907d91ba82f71c/KI-HERO-DreamKitchenDSKsat.jpg' alt="example of cleaning service" />
                <div style={{position: 'absolute', top: '60%',transform: 'translateY(-50%)', left: '60%',transform: 'translateX(-50%)', background: 'rgba(0, 0, 0, .5)'}}>
                    <h1 style={{color: 'white', fontSize: '50px'}}>Title 2</h1>
                    <p>Cool description</p>
                    <Button className="CheckButton">
                        Check it out!
                    </Button>
                </div>
            </div>
        </Carousel> */}
        <div style={{position: 'relative'}}>
            <img style={{height: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1723471298/Marketing/Project_20240811070924_xonkzg.png' alt="fine rug example" />
            <div style={{position: 'absolute', top: '20%',transform: 'translateY(-50%)', left: '20%',transform: window.innerWidth > 800 ? 'translateX(-60%)' : 'translateX(-20%)', background: 'rgba(0, 0, 0, .2)', zIndex: 10, width: window.innerWidth > 800 ? '30%' : 'fit-content', paddingLeft: '5px'}}>
                <h1 style={{color: 'white', fontSize: '3vh'}}>Bring your home to life!</h1>
                <p style={{color: 'white'}}>Breathe new life into your old devices to organize and decorate your home!</p>
            </div>
        </div>
      </div>




      <div style={{display: 'flex', flexDirection: window.innerWidth > 800 ? 'row' : 'column', alignItems: 'center', justifyContent: 'center', padding: '0 5%', marginTop: '50px'}}>
        <div style={{display: 'flex', flexDirection: 'column', width: window.innerWidth > 800 ? '50%' : '100%', padding: '5%'}}>
          <h5 style={{textAlign: 'center'}}>Organize your home</h5>
          <p style={{textAlign: 'center'}}>MyDisplay.io provides a calendar, tasks, lists, and more to help keep your day-to-day life organized and exciting! MyDisplay.io can be used by everyone, so assign tasks and watch your kid's excitement as the confetti drops when they complete all their tasks!</p>
        </div>
        <img style={{width: window.innerWidth > 800 ? '50%' : '100%'}} src="https://res.cloudinary.com/dk1bgtbr9/image/upload/v1723471296/Marketing/Project_20240811080123_otnzjc.png" alt="test" />
      </div>
      <div style={{display: 'flex', flexDirection: window.innerWidth > 800 ? 'row' : 'column', alignItems: 'center', justifyContent: 'center', padding: '5%'}}>
        {window.innerWidth > 800 ? <img style={{width: '50%',border: '3px solid black',borderRadius: '20px'}} src="https://res.cloudinary.com/dk1bgtbr9/image/upload/v1723471296/Marketing/tabletop_20240811071923_sug01g.png" alt="test" />  : null}
        <div style={{display: 'flex', flexDirection: 'column', width: window.innerWidth > 800 ? '50%' : '100%', padding: '5%'}}>
          <h5 style={{textAlign: 'center'}}>Recycle Your Old Screens</h5>
          <p style={{textAlign: 'center'}}>Recycle your old screens and use them to display your desired content. All you need is a web browser!</p>
        </div>
        {window.innerWidth <= 800 ? <img style={{width: '100%',border: '3px solid black',borderRadius: '20px'}} src="https://res.cloudinary.com/dk1bgtbr9/image/upload/v1723471296/Marketing/tabletop_20240811071923_sug01g.png" alt="test" />  : null}
      </div>
      <div style={{display: 'flex', flexDirection: window.innerWidth > 800 ? 'row' : 'column', alignItems: 'center', justifyContent: 'center', padding: '5%'}}>
        <div style={{display: 'flex', flexDirection: 'column', width: window.innerWidth > 800 ? '50%' : '100%', padding: '5%'}}>
          <h5 style={{textAlign: 'center'}}>Access From Anywhere, From Any Device</h5>
          <p style={{textAlign: 'center'}}>Since MyDisplay.io is a web app, you can access it from any device with a compatible web browser. It doesn't matter what type of device you use! Helpful for maintaining content accross different devices!</p>
        </div>
        {window.innerWidth > 800 ? <img style={{width: window.innerWidth > 800 ? '50%' : '100%',border: '3px solid black',borderRadius: '20px'}} src="https://res.cloudinary.com/dk1bgtbr9/image/upload/v1723471218/Marketing/pexels-mediamodifier-61455177-8066712_vqyvu8.jpg" alt="test" /> : null}
      </div>



      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '5%'}}>
        {/* <img style={{width: '50%',border: '3px solid black',borderRadius: '20px', padding: '5%'}} src="https://i.fbcd.co/products/resized/resized-750-500/alhamdulillah-9e46aba76a1f0ea7f8a30a016be61b7edf5be28145a08fb16e02f56b004f30be.webp" alt="test" /> */}
        <div style={{display: 'flex', flexDirection: 'column', width: window.innerWidth > 800 ? '65%' : '100%', padding: '5%'}}>
          <Typography>Frequently Asked Questions</Typography>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{margin: 0, padding: 0}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" style={{backgroundColor: expanded === 'panel1' ? 'rgba(0,0,0,0.05)' : 'rgba(0, 0, 0, .03)'}}>Do I need to buy a new device/screen?</AccordionSummary>
            <AccordionDetails>
              No! MyDisplay.io is designed as a web app so all you need is access to a Javascript-enabled web browser (most browsers &#128516;).
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{margin: 0, padding: 0}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header" style={{backgroundColor: expanded === 'panel2' ? 'rgba(0,0,0,0.05)' : 'rgba(0, 0, 0, .03)'}}>Is there a monthly subscription cost?</AccordionSummary>
            <AccordionDetails>
              Absolutely not! We don't believe in subscriptions. A one time fee unlocks all features for you to enjoy!
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{margin: 0, padding: 0}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header" style={{backgroundColor: expanded === 'panel3' ? 'rgba(0,0,0,0.05)' : 'rgba(0, 0, 0, .03)'}}>Is there a separate mobile app I need to download?</AccordionSummary>
            <AccordionDetails>
              Yes and no. MyDisplay.io is a web app which means you only need a web browser to access it. The following recommendation is 100% optional is meant to make it easier to access the app. If you would like to access it from a mobile device, we suggest installing the web app to your home screen for easy access. 
              <p>If you are using an Android device:</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>1. Open the webpage in the Google Chrome app.</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>2. Tap the 3 vertical dots in the top right of the screen to show options.</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>3. Tap on 'Add to Home screen' to install the app to your phone.</p>
              <p>If you are using an iOS device:</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>1. Open the webpage in the Safari app.</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>2. Tap the 'share' button.</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>3. Tap on 'Add to Home screen' to install the app to your phone.</p>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{margin: 0, padding: 0}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header" style={{backgroundColor: expanded === 'panel4' ? 'rgba(0,0,0,0.05)' : 'rgba(0, 0, 0, .03)'}}>How do I share my calendar/display with someone else?</AccordionSummary>
            <AccordionDetails>
              <p>Once you have created an account:</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>1. Click the big blue home button at top of any page.</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>2. Click 'Settings.'</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>3. Under 'Guest Account Access,' enter the email address (currently only Gmail accounts are supported) if the account you'd like to grant access to.</p>
              <p>NOTE: Each email address can only be associated with one MyDisplay account. So a guest user should not try to create a new account otherwise they will lose access to the account that granted them guest access.</p>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{margin: 0, padding: 0}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5-content" id="panel5-header" style={{backgroundColor: expanded === 'panel5' ? 'rgba(0,0,0,0.05)' : 'rgba(0, 0, 0, .03)'}}>Are any new features being planned?</AccordionSummary>
            <AccordionDetails>
              We are constantly working to improve our product. Our list of updates is long and we try our best to incorporate feedback and new ideas from our clients in prioritizing what we work on. If you have an idea for a new feature, send us some feedback and we'll look into it!
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{margin: 0, padding: 0}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6-content" id="panel6-header" style={{backgroundColor: expanded === 'panel6' ? 'rgba(0,0,0,0.05)' : 'rgba(0, 0, 0, .03)'}}>Is there a limit to how many screens I can display this on?</AccordionSummary>
            <AccordionDetails>
              No limits! Once you have an account, feel free to use it on as many screens as you'd like.
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} style={{margin: 0, padding: 0}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7-content" id="panel7-header" style={{backgroundColor: expanded === 'panel7' ? 'rgba(0,0,0,0.05)' : 'rgba(0, 0, 0, .03)'}}>What is the refund policy?</AccordionSummary>
            <AccordionDetails>
              At this time, we are unable to support refunds. All sales are final.
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} style={{margin: 0, padding: 0}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8-content" id="panel8-header" style={{backgroundColor: expanded === 'panel8' ? 'rgba(0,0,0,0.05)' : 'rgba(0, 0, 0, .03)'}}>How do I get started?</AccordionSummary>
            <AccordionDetails>
              <p>If you are a new user: </p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>1. Click 'Unlock Your Display.' This will take you to our sign in page. Please login via a Google account. This way, you have one less login/password to remember!</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>2. Once you've created an account, you will be redirected to our payments page. Click 'checkout' to be redirected to a stripe checkout page. We use Stripe to manage all payments so that we can focus on developing a better experience for you and they can focus on protecting your payment information.</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>3. Once you've completed your purchase, you should be redirected to the 'Family' screen where you can add your family members and start using the app right away!</p>
              <p>If you already have an account:</p>
              <p style={{margin: '0 auto', padding: '0 auto'}}>Just click login to return to the app!</p>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

    </div>
  )
}

function mapStateToProps(state) {
  return {
          auth: state.auth, 
         }
}

export default withRouter(connect(mapStateToProps,{})(Landing))