export const FETCH_USER = 'fetch_user';
export const ADD_FAMILY = 'add_family';
export const GET_FAMILY = 'get_family';
export const EDIT_FAMILY = 'edit_family';
export const REMOVE_FAMILY = 'remove_family';
export const FETCH_EVENTS = 'fetch_events';
export const ADD_EVENT = 'add_event';
export const REMOVE_EVENT = 'remove_event';
export const EDIT_EVENT = 'edit_event';
export const ADD_TODO = 'add_todo';
export const REMOVE_TODO = 'remove_todo';
export const GET_TODOS = 'get_todos';
export const CLEAR_TODOS = 'clear_todos';
export const EDIT_TODO = 'edit_todo'
export const ADD_LIST_ITEM = 'add_list_item';
export const REMOVE_LIST_ITEM = 'remove_list_item';
export const EDIT_LIST_ITEM = 'edit_list_item'
export const GET_LISTS = 'get_lists';
export const CLEAR_LIST_ITEMS = 'clear_list_items';
export const DELETE_LIST = 'delete_list'
export const CREATE_LIST = 'create_list'
export const MOVE_LIST = 'move_list'
export const ADD_COUNTDOWN = 'add_countdown'
export const REMOVE_COUNTDOWN = 'remove_countdown'
export const GET_COUNTDOWN = 'get_countdown'
export const ADD_GUEST = 'add_guest'
export const REMOVE_GUEST = 'remove_guest'
export const GET_GUESTS = 'get_guests'
export const GET_POSITIONS = 'get_positions';
export const ADD_POSITION = 'add_position';
export const REMOVE_POSITION = 'remove_position';
export const GET_SETTINGS = 'get_settings';
export const SET_SETTING = 'set_setting';
export const ADD_PHOTO = 'add_photo'
export const REMOVE_PHOTO = 'remove_photo'
export const GET_PHOTOS = 'get_photos'
export const SET_PHOTO_INFO = 'set_photo_info'
export const GET_NFL_DATA = 'get_nfl_data'