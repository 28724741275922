import axios from 'axios';
import { FETCH_USER, ADD_FAMILY, GET_FAMILY, REMOVE_FAMILY, FETCH_EVENTS, ADD_EVENT, REMOVE_EVENT, EDIT_EVENT, GET_TODOS, EDIT_TODO, ADD_TODO, REMOVE_TODO, CLEAR_TODOS, GET_LISTS, ADD_LIST_ITEM, EDIT_LIST_ITEM, REMOVE_LIST_ITEM, CLEAR_LIST_ITEMS, DELETE_LIST, CREATE_LIST, ADD_COUNTDOWN, REMOVE_COUNTDOWN, GET_COUNTDOWN, GET_GUESTS, ADD_GUEST, REMOVE_GUEST, GET_POSITIONS, ADD_POSITION, REMOVE_POSITION, SET_SETTING, GET_SETTINGS, GET_PHOTOS, ADD_PHOTO, REMOVE_PHOTO, SET_PHOTO_INFO, EDIT_FAMILY, GET_NFL_DATA, MOVE_LIST } from './types';

export const fetchUser = () => async dispatch => {
    const res = await axios.get('/api/current_user')
    dispatch({type: FETCH_USER, payload: res.data})
}

export const addFamily = (fammem) => async dispatch => {
    const res = await axios.post('/api/add_family',fammem)
    dispatch({type: ADD_FAMILY, payload: res.data})
}

export const editFamilyMember = (fammem) => async dispatch => {
    const res = await axios.post('/api/edit_family',fammem)
    dispatch({type: EDIT_FAMILY, payload: res.data})
}

export const getFamily = () => async dispatch => {
    const res = await axios.get('/api/get_family')
    dispatch({type: GET_FAMILY, payload: res.data})
}

export const removeFamily = (fammem) => async dispatch => {
    const res = await axios.post('/api/remove_family',{fammem})
    dispatch({type: REMOVE_FAMILY, payload: res.data})
}

export const getEvents = () => async dispatch => {
    const res = await axios.get('/api/get_events')
    dispatch({type: FETCH_EVENTS, payload: res.data})
}

export const addEventToMDB = (eventname) => async dispatch => {
    const res = await axios.post('/api/add_event',eventname)
    dispatch({type: ADD_EVENT, payload: res.data})
}

export const editEvent = (event) => async dispatch => {
    const res = await axios.post('/api/edit_event',event)
    dispatch({type: EDIT_EVENT, payload: res.data})
}

export const removeEvent = (eventname) => async dispatch => {
    const res = await axios.post('/api/remove_event',eventname)
    dispatch({type: REMOVE_EVENT, payload: res.data})
}

export const getTodos = () => async dispatch => {
    const res = await axios.get('/api/get_todos')
    dispatch({type: GET_TODOS, payload: res.data})
}

export const addTodo = (eventname) => async dispatch => {
    const res = await axios.post('/api/add_todo',eventname)
    dispatch({type: ADD_TODO, payload: res.data})
}

export const editTodo = (eventname) => async dispatch => {
    const res = await axios.post('/api/edit_todo',eventname)
    dispatch({type: EDIT_TODO, payload: res.data})
}

export const removeTodo = (eventname) => async dispatch => {
    const res = await axios.post('/api/remove_todo',eventname)
    dispatch({type: REMOVE_TODO, payload: res.data})
}

export const clearTodo = (eventname) => async dispatch => {
    const res = await axios.post('/api/clear_todo',eventname)
    dispatch({type: CLEAR_TODOS, payload: res.data})
}

export const toggleTodo = (eventname) => async dispatch => {
    const res = await axios.post('/api/toggle_todo',eventname)
}

export const getLists = () => async dispatch => {
    const res = await axios.get('/api/get_lists')
    dispatch({type: GET_LISTS, payload: res.data})
}

export const addListItem = (eventname) => async dispatch => {
    const res = await axios.post('/api/add_list_item',eventname)
    dispatch({type: ADD_LIST_ITEM, payload: res.data})
}

export const editListItem = (event) => async dispatch => {
    const res = await axios.post('/api/edit_list_item',event)
    dispatch({type: EDIT_LIST_ITEM, payload: res.data})
}

export const removeListItem = (eventname) => async dispatch => {
    const res = await axios.post('/api/remove_list_item',eventname)
    dispatch({type: REMOVE_LIST_ITEM, payload: res.data})
}

export const clearListItem = (eventname) => async dispatch => {
    const res = await axios.post('/api/clear_todo_items',eventname)
    dispatch({type: CLEAR_LIST_ITEMS, payload: res.data})
}

export const toggleListItem = (eventname) => async dispatch => {
    const res = await axios.post('/api/toggle_list_item',eventname)
}

export const deleteList = (eventname) => async dispatch => {
    const res = await axios.post('/api/delete_list',eventname)
    dispatch({type: DELETE_LIST, payload: res.data})
}

export const createList = (eventname) => async dispatch => {
    const res = await axios.post('/api/create_list',eventname)
    dispatch({type: CREATE_LIST, payload: res.data})
}

export const moveList = (eventname) => async dispatch => {
    const res = await axios.post('/api/move_list',eventname)
    dispatch({type: MOVE_LIST, payload: res.data})
}

export const getCountdowns = () => async dispatch => {
    const res = await axios.get('/api/get_countdowns')
    dispatch({type: GET_COUNTDOWN, payload: res.data})
}

export const addCountdown = (eventname) => async dispatch => {
    const res = await axios.post('/api/add_countdown',eventname)
    dispatch({type: ADD_COUNTDOWN, payload: res.data})
}

export const removeCountdown = (eventname) => async dispatch => {
    const res = await axios.post('/api/remove_countdown',eventname)
    dispatch({type: REMOVE_COUNTDOWN, payload: res.data})
}

export const getGuests = () => async dispatch => {
    const res = await axios.get('/api/get_guests')
    dispatch({type: GET_GUESTS, payload: res.data})
}

export const addGuest = (eventname) => async dispatch => {
    const res = await axios.post('/api/add_guest',eventname)
    dispatch({type: ADD_GUEST, payload: res.data})
}

export const removeGuest = (eventname) => async dispatch => {
    const res = await axios.post('/api/remove_guest',eventname)
    dispatch({type: REMOVE_GUEST, payload: res.data})
}

export const getPositions = () => async dispatch => {
    const res = await axios.get('/api/get_positions')
    dispatch({type: GET_POSITIONS, payload: res.data})
}

export const addPosition = (values) => async dispatch => {
    const res = await axios.post('/api/add_position',values)
    dispatch({type: ADD_POSITION, payload: res.data})
}

export const removePosition = (values) => async dispatch => {
    const res = await axios.post('/api/remove_position',values)
    dispatch({type: REMOVE_POSITION, payload: res.data})
}

export const getSettings = () => async dispatch => {
    const res = await axios.get('/api/get_settings')
    dispatch({type: GET_SETTINGS, payload: res.data})
}

export const setSetting = (values) => async dispatch => {
    const res = await axios.post('/api/set_setting',values)
    dispatch({type: SET_SETTING, payload: res.data})
}

export const getPhotos = () => async dispatch => {
    const res = await axios.get('/api/get_photos')
    dispatch({type: GET_PHOTOS, payload: res.data})
}

export const addPhoto = (eventname) => async dispatch => {
    const res = await axios.post('/api/add_photo',eventname)
    dispatch({type: ADD_PHOTO, payload: res.data})
}

export const removePhoto = (eventname) => async dispatch => {
    const res = await axios.post('/api/remove_photo',eventname)
    dispatch({type: REMOVE_PHOTO, payload: res.data})
}

export const setPhotoInfo = (data) => async dispatch => {
    dispatch({type: SET_PHOTO_INFO, payload: data})
}

export const getNfldata = (data) => async dispatch => {
    const res = await axios.post('/api/get_nfldata',data)
    dispatch({type: GET_NFL_DATA, payload: res.data})
}

export const setLastTeam = (data) => async dispatch => {
    const res = await axios.post('/api/set_lastteam',data)
    console.log(res.data)
    dispatch({type: FETCH_USER, payload: res.data})
}